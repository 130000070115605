import { useMemo } from 'react';
import { useQlubRouter } from '@clubpay/customer-common-module/src/hook/router';
import { QlubKitTheme } from '@qlub-dev/qlub-kit';
import blueTheme from '@qlub-dev/qlub-kit/dist/theme/blueTheme';
import darkRedTheme from '@qlub-dev/qlub-kit/dist/theme/darkRedTheme';
import greenTheme from '@qlub-dev/qlub-kit/dist/theme/greenTheme';
import pinkTheme from '@qlub-dev/qlub-kit/dist/theme/pinkTheme';
import blackTheme from '@qlub-dev/qlub-kit/dist/theme/blackTheme';
import orangeTheme from '@qlub-dev/qlub-kit/dist/theme/orangeTheme';
import darkGoldTheme from '@qlub-dev/qlub-kit/dist/theme/darkGoldTheme';

import { ThemeModeEnum } from '@clubpay/customer-common-module/src/context/config';

const getTheme = () => {
    const { theme } = useQlubRouter();
    const qlubTheme = useMemo(() => {
        switch (theme) {
            case ThemeModeEnum.Orange:
                return orangeTheme;
            case ThemeModeEnum.Blue:
                return blueTheme;
            case ThemeModeEnum.Darkred:
                return darkRedTheme;
            case ThemeModeEnum.Green:
                return greenTheme;
            case ThemeModeEnum.Pink:
                return pinkTheme;
            case ThemeModeEnum.Black:
                return blackTheme;
            case ThemeModeEnum.DarkGold:
                return darkGoldTheme;
            default:
            case ThemeModeEnum.Normal:
                return QlubKitTheme;
        }
    }, [theme]);
    return { theme: qlubTheme };
};

export default getTheme;
