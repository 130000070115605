// @ts-ignore
const en = require('../public/locales/en/common.json');
const ja = require('../public/locales/ja/common.json');
const de = require('../public/locales/de/common.json');
const ar = require('../public/locales/ar/common.json');
const pt = require('../public/locales/pt/common.json');
const tr = require('../public/locales/tr/common.json');
const zh = require('../public/locales/zh/common.json');
const hk = require('../public/locales/hk/common.json');
const es = require('../public/locales/es/common.json');
const enSG = require('../public/locales/en-sg/common.json');
const ko = require('../public/locales/ko/common.json');
const ru = require('../public/locales/ru/common.json');

const i18n = {
    translations: {
        en,
        ja,
        de,
        ar,
        pt,
        tr,
        zh,
        hk,
        es,
        'en-sg': enSG,
        ko,
        ru,
    },
    defaultLang: 'en',
    useBrowserDefault: true,
    languageDataStore: 'query',
};

module.exports = i18n;
