import { menuApiUrlPrefix } from '@clubpay/customer-common-module/src/repository';

export const apiEndpoints = {
    menu: `${menuApiUrlPrefix}/:table_topology/menu`,
    socialMenu: `${menuApiUrlPrefix}/:table_topology/social`,
    category: `${menuApiUrlPrefix}/:table_topology/:menuId/category/:hashId`,
    product: `${menuApiUrlPrefix}/:table_topology/:menuId/product/:hashId`,
    productMany: `${menuApiUrlPrefix}/:table_topology/product/list`,
    orderCreate: `${menuApiUrlPrefix}/:table_topology/order`,
    orderEdit: `${menuApiUrlPrefix}/:table_topology/order`,
    sessionEdit: `${menuApiUrlPrefix}/:table_topology/order/session`,
    orderGet: `${menuApiUrlPrefix}/:table_topology/order/:refId`,
    orderGetHistory: `${menuApiUrlPrefix}/:table_topology/order/history`,
    orderGetActiveCount: `${menuApiUrlPrefix}/:table_topology/order/count`,
    userInfoGet: `${menuApiUrlPrefix}/:table_topology/user-info`,
    userInfoSet: `${menuApiUrlPrefix}/:table_topology/user-info`,
    pagerGet: `${menuApiUrlPrefix}/:table_topology/pager/acquire`,
    dynamicQrValidate: `${menuApiUrlPrefix}/:table_topology/order/validate/dynamic-qr`,
};

export default apiEndpoints;
