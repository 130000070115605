/*
    Creation Time: 2021 - Sep - 26
    Created by:  (hamidrezakk)
    Maintainers:
       1.  HamidrezaKK (hamidrezakks@gmail.com)
    Auditor: HamidrezaKK
    Copyright Qlub_ 2022
*/

import React, { useCallback, useEffect, useState } from 'react';
import Head from 'next/head';
import { AppProps } from 'next/app';
import { CssBaseline } from '@mui/material';
import { EmotionCache } from '@emotion/react';
import { SnackbarProvider } from 'notistack';
import { ConfirmProvider } from 'material-ui-confirm';
import Routes from '@clubpay/customer-common-module/src/service/routes';
import { ConfigContextProvider } from '@clubpay/customer-common-module/src/context/config';
import CustomThemeProvider from '@/theme/theme-provider';
import { NextPageWithLayout } from '@/layout/types';
import { VendorContextProvider } from '@clubpay/customer-common-module/src/context/vendor';
import { IURLTopology, useQlubRouter } from '@clubpay/customer-common-module/src/hook/router';
import { AuthContextProvider } from '@clubpay/customer-common-module/src/context/auth';
import { SplashContextProvider } from '@clubpay/customer-common-module/src/context/splash';
import useWindowResize from '@/hooks/useWindowResize';
import MenuAPIManager from '@/repositories/menu';
import { QrPasscodeContextProvider } from '@/contexts/qrPasscode';
import { GoogleTagManager } from '@next/third-parties/google';
import { LoyaltyProvider } from '@clubpay/loyalty/dist/providers';
import FirebaseService from '@clubpay/customer-common-module/src/service/firebase';
import { gaToken } from '@clubpay/customer-common-module/src/repository/axios/constants';
import { LayoutContextProvider } from '@clubpay/customer-common-module/src/context/layout';
import '@/styles/global.scss';

interface MyAppProps extends AppProps {
    emotionCache?: EmotionCache;
}

type AppPropsWithLayout = MyAppProps & {
    Component: NextPageWithLayout;
};

function QlubApp(props: AppPropsWithLayout) {
    const menuApi = MenuAPIManager.getInstance();
    const { Component, pageProps } = props;
    const [mounted, setMounted] = useState(false);
    const { asPath, push, route } = useQlubRouter();
    // Checks if the route exists
    useEffect(() => {
        FirebaseService.getInstance();

        setMounted(true);
        if (asPath.indexOf('[') === -1 && Routes.getInstance().exists(asPath)) {
            push(asPath);
        }
    }, []);

    const getMenu = useCallback((url: IURLTopology) => {
        return menuApi.getMenu(url, { page: 0, limit: 2 }).then((response) => {
            return response?.rows?.length === 1 ? response.rows[0].id : '';
        });
    }, []);

    useWindowResize(route);

    const getLayout = Component.getLayout ?? ((page) => page);

    if (!mounted) {
        return null;
    }

    return (
        <div className="root-container">
            <Head>
                <meta
                    name="viewport"
                    content="width=device-width,initial-scale=1,minimum-scale=1,maximum-scale=5,user-scalable=yes"
                />
            </Head>
            <GoogleTagManager gtmId={gaToken} />
            <CustomThemeProvider>
                <SnackbarProvider
                    maxSnack={3}
                    disableWindowBlurListener
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                >
                    <ConfirmProvider>
                        <AuthContextProvider>
                            <ConfigContextProvider>
                                <VendorContextProvider getMenu={getMenu}>
                                    <QrPasscodeContextProvider>
                                        <SplashContextProvider>
                                            <LayoutContextProvider>
                                                <LoyaltyProvider>
                                                    {/* CssBaseline kickstart an elegant, consistent, and simple baseline to build upon. */}
                                                    <CssBaseline />
                                                    {getLayout(<Component {...pageProps} />)}
                                                </LoyaltyProvider>
                                            </LayoutContextProvider>
                                        </SplashContextProvider>
                                    </QrPasscodeContextProvider>
                                </VendorContextProvider>
                            </ConfigContextProvider>
                        </AuthContextProvider>
                    </ConfirmProvider>
                </SnackbarProvider>
            </CustomThemeProvider>
        </div>
    );
}

export default QlubApp;
